import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "../screens/home";
import {store} from "../redux/store";
import { Provider } from "react-redux";

const MainRouter = () => {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                </Routes>
            </Router>
        </Provider>
    )
}

export default MainRouter;
