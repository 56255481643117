import React from 'react';
import './styles.css';
const Home = () => {
    return (
        <div>
            <div className="red-dot blur"></div>
            <div className="big-purple-dot"></div>
            <div className="top-blue-dot blur"></div>
            <div className="blue-dot blur"></div>
            <div className="center">
                <h1>Coming Soon</h1>
            </div>
        </div>
    )
}

export default Home;
